.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    /* Adjust to column layout */
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 30%;
}

.portfolio__item h3 {
    margin: 2rem 0;
}

.portfolio__item p {
    margin: 0 0 1.2rem 0;
    font-size: 0.9rem;
}

.portfolio__item-cta {
    display: flex;
    flex-direction: column;
    /* Keep this for column layout */
    align-items: center;
    /* Center the button */
    margin-top: auto;
    /* Move the button to the bottom */
    margin-bottom: 1rem;
    /* Add margin to the bottom for spacing */
}

.portfolio__item-content {
    height: 40%;
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
