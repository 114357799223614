resources.css #resources {
    text-align: center;
}

.resources__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    padding: 20px;
    /* Added padding */
}

.resources__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}

.resources__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.resources__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: auto;
}

.resources__item h3 {
    margin: 1rem 0;
}

.resources__item p {
    margin: 0.5rem 0 1.2rem 0;
    font-size: 0.9rem;
}

.resources__item-cta {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.resources__item-content {
    height: auto;
}

.expanded-container {
    width: 100%;
    padding: 20px;
    /* Added padding */
}

.expanded {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem;
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.expanded:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.expanded .resources__item-image {
    height: auto;
}

.expanded .resources__item-content {
    height: auto;
    margin-top: 1rem;
}

.expanded button {
    margin-top: 1rem;
}

@media screen and (max-width: 1024px) {
    .resources__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .resources__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
