.experience__container {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
}

.experience__wrapper {
    width: 70%;
    margin: 0 auto;
}

.experience__row {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid transparent;
    /* Adjusted padding for space between icons and border */
    padding: 6.5rem;
    transition: var(--transition);
    margin-bottom: 2rem;
}

.experience__row:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.experience__row h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.experience__details-icon {
    font-size: 4rem;
    color: var(--color-primary);
}

@media screen and (max-width: 600px) {
    .experience__wrapper {
        width: 100%;
    }
    .experience__container {
        gap: 1rem;
    }
    .experience__row {
        padding: 2rem 1rem;
    }
}
